@layer tailwind-base, antd;

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer components {
  /* Page */
  .page {
    @apply flex flex-col bg-white rounded-xl h-full;
  }
  .page-header {
    @apply p-sm border-b-1 border-b-default-100;
  }
  .page-header-buttons-container {
    @apply flex gap-2xs;
  }
  .page-title-container {
    @apply flex flex-col items-start;
  }
  .page-title {
    @apply text-xl text-secondary-500 font-bold;
  }
  .page-subtitle {
    @apply text-sm text-default-500;
  }
  .page-body {
    @apply flex-1 overflow-y-auto;
  }

  /* Modal Header */
  .modal-header {
    @apply mb-md;
  }
  .modal-title {
    @apply text-secondary-500;
  }
  .modal-subtitle {
    @apply text-sm text-gray-500 font-normal;
  }

  /* Modal Footer */
  .modal-footer {
    @apply flex gap-2 justify-end;
  }

  /* Upload Products Step */
  .upload-products-step-title {
    @apply text-secondary-500 font-semibold text-lg text-center;
  }
  .upload-products-step-description {
    @apply text-default-500 text-center;
  }
  .upload-products-step-body {
    @apply mt-md;
  }
}

@layer utilities {
  .v-stack {
    @apply flex flex-col;
  }
  .h-stack {
    @apply flex flex-row;
  }
}

h2 + section {
  overflow-y: visible !important;
}
